import React, { useCallback, useEffect, useState } from "react";
import { Container, Text, Button, Dropdown, Loading } from "@nextui-org/react";
import { useLocation, useNavigate } from "react-router-dom";
import { useCategory } from "../../context/CategoryContext";
import { useAuth } from "../../context/AuthContext";
import { getProductBatch, getCategories } from "../../api/dashboard";
import { useSnackbar } from "notistack";
import { formatCategoryName } from "../../utils/helpers";

interface Category {
  category_id: number;
  category_name: string;
}

interface HeaderProps {
  username?: string;
  showCategory?: boolean;
  showRefresh?: boolean;
}

const Header: React.FC<HeaderProps> = ({
  username = "User",
  showCategory = true,
  showRefresh = true,
}) => {
  const location = useLocation();
  const navigate = useNavigate();
  const { selectedCategory, setSelectedCategory } = useCategory();
  const { user } = useAuth();
  const { enqueueSnackbar } = useSnackbar();
  const [categories, setCategories] = useState<Category[]>([]);
  const [loading, setLoading] = useState(false);
  const [remainingProductsByCategory, setRemainingProductsByCategory] =
    useState<any>(null);

  // Subscribe to get_batch events to update remaining count
  useEffect(() => {
    const updateRemainingCount = (event: CustomEvent) => {
      if (event.detail?.remaining_count !== undefined && selectedCategory) {
        setRemainingProductsByCategory({
          category_id: selectedCategory,
          remaining_products: event.detail.remaining_count,
        });
      }
    };

    window.addEventListener(
      "get_batch_response",
      updateRemainingCount as EventListener
    );
    return () => {
      window.removeEventListener(
        "get_batch_response",
        updateRemainingCount as EventListener
      );
    };
  }, [selectedCategory]);

  useEffect(() => {
    const fetchCategories = async () => {
      setLoading(true);
      try {
        // Initial load of categories
        const response = await getCategories();
        if (response.data) {
          const categories = response.data.map((category: any) => ({
            category_id: category.category_id,
            category_name: category.category_name,
          }));
          setCategories(categories);
        }

        // Get latest count for selected category
        if (selectedCategory) {
          const batchResponse = await getProductBatch({
            includes: {
              source_category_id: selectedCategory,
            },
          });
          if (batchResponse.data.remaining_count !== undefined) {
            setRemainingProductsByCategory({
              category_id: selectedCategory,
              remaining_products: batchResponse.data.remaining_count,
            });
          }
        }
      } catch (error) {
        console.error("Error fetching categories:", error);
        enqueueSnackbar(
          "Failed to load categories: " + (error as Error).message,
          {
            variant: "error",
            autoHideDuration: 3000,
          }
        );
      } finally {
        setLoading(false);
      }
    };

    fetchCategories();
  }, [selectedCategory, location.pathname]);

  const handleCategoryChange = (keys: any) => {
    const selected = Array.from(keys)[0]?.toString();
    if (selected) {
      setSelectedCategory(selected);
      // Count will be updated by the useEffect when selectedCategory changes
      if (location.pathname.includes("/dashboard/tag")) {
        navigate("/dashboard/tag");
      }
    }
  };

  const handleRefresh = useCallback(async () => {
    if (!selectedCategory) {
      enqueueSnackbar("Please select a category first", {
        variant: "warning",
        autoHideDuration: 3000,
      });
      return;
    }

    try {
      const response = await getProductBatch({
        includes: {
          source_category_id: selectedCategory,
        },
        refresh: true,
      });
      // Update remaining count from the get_batch response
      if (response.data.remaining_count !== undefined) {
        setRemainingProductsByCategory({
          category_id: selectedCategory,
          remaining_products: response.data.remaining_count,
        });
      }

      if (location.pathname.includes("/dashboard/tag")) {
        // Force a reload of the tag page
        navigate("/dashboard");
        setTimeout(() => navigate("/dashboard/tag"), 0);
      }

      enqueueSnackbar("Successfully refreshed product batch", {
        variant: "success",
        autoHideDuration: 3000,
      });
    } catch (error) {
      console.error("Error refreshing batch:", error);
      enqueueSnackbar("Failed to refresh batch: " + (error as Error).message, {
        variant: "error",
        autoHideDuration: 3000,
      });
    }
  }, [selectedCategory, navigate, location.pathname, enqueueSnackbar]);

  return (
    <div
      style={{
        width: "100%",
        height: "76px",
        backgroundColor: "white",
        borderBottom: "1px solid #eaeaea",
        position: "sticky",
        top: 0,
        zIndex: 100,
      }}
    >
      <Container
        display="flex"
        alignItems="center"
        justify="space-between"
        css={{
          height: "100%",
          padding: "0 24px",
          maxWidth: "100%",
        }}
      >
        <div style={{ display: "flex", alignItems: "center", gap: "24px" }}>
          <Text
            b
            size={20}
            css={{
              color: "$text",
              marginRight: "24px",
            }}
          >
            Product Matching Dashboard
          </Text>
          {showCategory && (
            <>
              <Dropdown>
                <Dropdown.Button
                  flat
                  css={{ tt: "capitalize", minWidth: "150px" }}
                  disabled={loading}
                >
                  {loading ? (
                    <Loading size="sm" />
                  ) : categories.find(
                      (cat) => cat.category_id.toString() === selectedCategory
                    ) ? (
                    formatCategoryName(
                      categories.find(
                        (cat) => cat.category_id.toString() === selectedCategory
                      )!.category_name
                    )
                  ) : (
                    "Select Category"
                  )}
                </Dropdown.Button>
                <Dropdown.Menu
                  aria-label="Category selection"
                  selectionMode="single"
                  selectedKeys={selectedCategory ? [selectedCategory] : []}
                  onSelectionChange={handleCategoryChange}
                  disallowEmptySelection
                >
                  {categories.map((category) => (
                    <Dropdown.Item key={category.category_id.toString()}>
                      {formatCategoryName(category.category_name)}
                    </Dropdown.Item>
                  ))}
                </Dropdown.Menu>
              </Dropdown>
              {!remainingProductsByCategory ? (
                <Text>No products remaining to be tagged</Text>
              ) : (
                <Text size="sm" css={{ color: "$gray700", fontSize: "18px" }}>
                  <span style={{ fontWeight: "bold" }}>
                    {remainingProductsByCategory.remaining_products}
                  </span>{" "}
                  products remaining to be tagged
                </Text>
              )}
            </>
          )}
        </div>

        <div style={{ display: "flex", alignItems: "center", gap: "16px" }}>
          <Text css={{ color: "$text" }}>
            Welcome, {user?.name?.split(" ")?.[0] || user?.email || username}
          </Text>
          {showRefresh && (
            <Button
              auto
              flat
              onClick={handleRefresh}
              disabled={!selectedCategory}
            >
              Refresh Batch
            </Button>
          )}
        </div>
      </Container>
    </div>
  );
};

export default Header;
