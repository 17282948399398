import React from "react";
import ReviewDashboard from "../components/ReviewDashboard";
import { useAuth } from "../context/AuthContext";

const ReviewPage: React.FC = () => {
  const { user } = useAuth();
  return <ReviewDashboard />;
};

export default ReviewPage;
