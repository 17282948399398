import React, { useState, useEffect, useCallback } from 'react';
import { Table, Text, Loading, Dropdown, Popover, Button, Spacer} from '@nextui-org/react';
import { get_user_analytics, get_category_analytics, get_overall_analytics } from '../api/dashboard';
import { formatCategoryName } from "../utils/helpers";
import { DatePicker } from '@mantine/dates';
import "@mantine/core/styles.css";
import "@mantine/dates/styles.css";
import "../styles/analytics.css";
import { useSnackbar } from "notistack";
import { useAuth } from "../context/AuthContext";
import { Navigate } from 'react-router-dom';


interface TaggerData {
  user: string;
  productsTagged: number;
  matched: number;
  not_matched: number;
}

interface CategoryData {
  category: string;
  category_id: number,
  totalTagged: number;
  totalMatched: number;
  totalUnmatched: number;
  taggingPending: number;
}

interface DatePickerProps {
  selected: Date | undefined;
  onChange: (date: Date | null) => void;
  placeholderText?: string;
  className?: string;
  maxDate?: Date;
  minDate?: Date;
}

interface OverallData {
  totalTagged: number;
  totalMatched: number;
  totalUnmatched: number;
  totalPending: number;
}

// Add these new types
type SortDescriptor = {
  column: string;
  direction: 'ascending' | 'descending';
};


const AnalyticsDashboard: React.FC = ()=> {
  const { user } = useAuth();
  const [categoryLoading, setCategoryLoading] = useState(false);
  const [taggerLoading, setTaggerLoading] = useState(false);
  const [categoryData, setCategoryData] = useState<CategoryData[]>([]);
  const [taggerData, setTaggerData] = useState<TaggerData[]>([]);
  const [selectedCategories, setSelectedCategories] = useState<Set<string>>(new Set());
  const [dateRange, setDateRange] = useState<[Date | null, Date | null]>([null, null]);
  const { enqueueSnackbar } = useSnackbar();
  const [isDatePopoverOpen, setIsDatePopoverOpen] = useState(false);
  const [overallLoading, setOverallLoading] = useState(false);
  const [overallData, setOverallData] = useState<OverallData | null>(null);

  // Add new sorting states
  const [taggerSort, setTaggerSort] = useState<SortDescriptor>({ 
    column: '', 
    direction: 'descending'
  });
  const [categorySort, setCategorySort] = useState<SortDescriptor>({ 
    column: '', 
    direction: 'descending'
  });
  // Add new loading state for overall refresh
  const [isRefreshing, setIsRefreshing] = useState(false);
  const isReviewer = user?.role?.includes("reviewer") || user?.role?.includes("admin");
  
  const sortData = <T extends object>(
    data: T[],
    column: keyof T,
    direction: 'ascending' | 'descending'
  ): T[] => {
    return [...data].sort((a, b) => {
      const aValue = a[column];
      const bValue = b[column];
      
      // Handle string comparisons (ascending by default)
      if (typeof aValue === 'string' && typeof bValue === 'string') {
        return direction === 'ascending' 
          ? aValue.localeCompare(bValue)
          : bValue.localeCompare(aValue);
      }
      
      // Handle numeric comparisons (descending by default)
      return direction === 'ascending' 
        ? Number(aValue) - Number(bValue)
        : Number(bValue) - Number(aValue);
    });
  };


// Default descending for numbers
  const hasActiveFilters = () => {
    return (
      selectedCategories.size > 0 || 
      (dateRange[0] !== null && dateRange[1] !== null)
    );
  };

  const handleReset = async () => {
    // Reset all filters
    setSelectedCategories(new Set());
    setDateRange([null, null]);
    setIsDatePopoverOpen(false);

    try {
      setIsRefreshing(true);
      // Run all API calls in parallel for faster loading after reset
      const [overallRes, categoryRes, taggerRes] = await Promise.all([
        get_overall_analytics(),
        get_category_analytics(),
        get_user_analytics({}) // Empty includes object since we reset filters
      ]);

      // Update states with fresh data
      if (overallRes?.data?.overall_analytics) {
        setOverallData({
          totalTagged: overallRes.data.overall_analytics.totalTagged || 0,
          totalMatched: overallRes.data.overall_analytics.totalMatched || 0,
          totalUnmatched: overallRes.data.overall_analytics.totalUnmatched || 0,
          totalPending: overallRes.data.overall_analytics.taggingPending || 0,
        });
      }

      if (categoryRes?.data?.category_board) {
        setCategoryData(categoryRes.data.category_board.map((item: any) => ({
          category: item.category,
          category_id: item.category_id,
          totalTagged: item.totalTagged,
          totalMatched: item.totalMatched,
          totalUnmatched: item.totalUnmatched,
          taggingPending: item.taggingPending
        })));
      }

      if (taggerRes?.data) {
        setTaggerData(taggerRes.data.leaderboard.map((item: any) => ({
          user: item.user,
          productsTagged: item.total,
          matched: item.matched,
          not_matched: item.not_matched
        })));
      }

      enqueueSnackbar("Filters reset and data refreshed", {
        variant: "success",
        autoHideDuration: 2000,
      });
    } catch (error: any) {
      enqueueSnackbar(error.message || "Failed to refresh data", {
        variant: "error",
        autoHideDuration: 2000,
      });
    } finally {
      setTimeout(() => {
        setIsRefreshing(false);
      }, 500);
    }
  };
  
  // Add initial load
  useEffect(() => {
    refreshData();
  }, []); // Initial load only

  const refreshData = useCallback(async () => {
    setIsRefreshing(true);
    try {
      // Run all API calls in parallel for faster loading
      const [overallRes, categoryRes, taggerRes] = await Promise.all([
        get_overall_analytics(),
        get_category_analytics(),
        get_user_analytics({
          includes: {
            ...(selectedCategories.size > 0 && {
              source_category_id: Array.from(selectedCategories).map(Number)
            }),
            ...(dateRange[0] && dateRange[1] && {
              tagged_timestamp: {
                start: dateRange[0].toLocaleDateString("en-CA"),
                end: dateRange[1].toLocaleDateString("en-CA"),
              }
            })
          }
        })
      ]);

      // Update all states with new data
      if (overallRes?.data?.overall_analytics) {
        setOverallData({
          totalTagged: overallRes.data.overall_analytics.totalTagged || 0,
          totalMatched: overallRes.data.overall_analytics.totalMatched || 0,
          totalUnmatched: overallRes.data.overall_analytics.totalUnmatched || 0,
          totalPending: overallRes.data.overall_analytics.taggingPending || 0,
        });
      }

      if (categoryRes?.data?.category_board) {
        setCategoryData(categoryRes.data.category_board.map((item: any) => ({
          category: item.category,
          category_id: item.category_id,
          totalTagged: item.totalTagged,
          totalMatched: item.totalMatched,
          totalUnmatched: item.totalUnmatched,
          taggingPending: item.taggingPending
        })));
      }

      if (taggerRes?.data) {
        setTaggerData(taggerRes.data.leaderboard.map((item: any) => ({
          user: item.user,
          productsTagged: item.total,
          matched: item.matched,
          not_matched: item.not_matched
        })));
      }
    } catch (error: any) {
      enqueueSnackbar(error.message || "Failed to refresh data", {
        variant: "error",
        autoHideDuration: 3000,
      });
    } finally {
      // Add a minimum loading time of 500ms for better UX
      setTimeout(() => {
        setIsRefreshing(false);
      }, 500);
    }
  }, [selectedCategories, dateRange, enqueueSnackbar]);

  // Update useEffect for filter changes
  useEffect(() => {
    refreshData();
  }, [selectedCategories, dateRange]);

  useEffect(() => {
    // Set up auto-refresh interval
    const refreshInterval = setInterval(() => {
      refreshData();
    }, 15000); // 15 seconds

    // Cleanup interval on component unmount
    return () => clearInterval(refreshInterval);
  }, [refreshData]);

  if (!isReviewer) {
    return <Navigate to="/" replace />;
  }
  return (
    <div className="analytics-container">
      {isRefreshing && <div className="global-refresh-loader" />}
      {/* Overall Analytics Table */}
      <div className="table-section">
        <Text h4>Overall Analytics</Text>
        {overallLoading ? (
          <div className="table-loading-container">
            <Loading size="lg" />
            <Text css={{ color: "$accents6" }}>Loading overall data...</Text>
          </div>
        ) : (
          <Table
            aria-label="Overall analytics table"
            css={{ minWidth: "100%" }}
          >
            <Table.Header>
              <Table.Column>Tagged</Table.Column>
              <Table.Column>Matched</Table.Column>
              <Table.Column>Not Matched</Table.Column>
              <Table.Column>Tagging Pending</Table.Column>
            </Table.Header>
            <Table.Body>
              <Table.Row>
                <Table.Cell>{overallData?.totalTagged || 0}</Table.Cell>
                <Table.Cell>{overallData?.totalMatched || 0}</Table.Cell>
                <Table.Cell>{overallData?.totalUnmatched || 0}</Table.Cell>
                <Table.Cell>{overallData?.totalPending || 0}</Table.Cell>
              </Table.Row>
            </Table.Body>
          </Table>
        )}
      </div>
      {/* Tagger Analytics Section */}
      <div className="table-section">
        <Text h4>Tagger Overview</Text>
        
        <div className="filters-container">
          <div className="filters-left">
            <Dropdown>
              <Dropdown.Button flat>
                {selectedCategories.size > 0 
                  ? `${selectedCategories.size} categories selected`
                  : "All Categories"}
              </Dropdown.Button>
              <Dropdown.Menu
                aria-label="Category selection"
                selectionMode="multiple"
                disallowEmptySelection={false}
                selectedKeys={selectedCategories}
                onSelectionChange={(keys) => {
                  const stringKeys = new Set(Array.from(keys).map(String));
                  setSelectedCategories(stringKeys);
                }}
              >
                {categoryData?.map((category: any) => (
                  <Dropdown.Item 
                    key={category.category_id.toString()}
                    textValue={category.category}
                  >
                    {formatCategoryName(category.category)}
                  </Dropdown.Item>
                ))}
              </Dropdown.Menu>
            </Dropdown>

            <Popover
              isOpen={isDatePopoverOpen}
              onOpenChange={setIsDatePopoverOpen}
              placement="bottom"
            >
              <Popover.Trigger>
                <Button flat>
                  {dateRange[0] && dateRange[1]
                    ? `${dateRange[0].toLocaleDateString()} - ${dateRange[1].toLocaleDateString()}`
                    : "Select Tagged Date Range"}
                </Button>
              </Popover.Trigger>
              <Popover.Content>
                <div className="date-picker-container">
                  <DatePicker
                    type="range"
                    value={dateRange}
                    onChange={(dates: [Date | null, Date | null]) => {
                      setDateRange(dates);
                      // Only close popover when both dates are selected
                      if (dates[0] && dates[1]) {
                        setIsDatePopoverOpen(false);
                      }
                    }}
                  />
                </div>
              </Popover.Content>
            </Popover>
          </div>

          <div className="filters-right">
            {hasActiveFilters() && (
              <Button 
                flat
                color="error"
                onClick={handleReset}
              >
                Reset Filters
              </Button>
            )}
            {/* <Button 
              color="primary"
              disabled={!hasActiveFilters()}
              onClick={handleApplyFilters}
            >
              Apply Filters
            </Button> */}
          </div>
        </div>

        {taggerLoading ? (
          <div className="table-loading-container">
            <Loading size="lg" />
            <Text css={{ color: "$accents6" }}>Loading tagger data...</Text>
          </div>
        ) : (
          <Table
            aria-label="Tagger analytics table"
            css={{ minWidth: "100%" }}
            sortDescriptor={taggerSort}
            onSortChange={(descriptor) => {
              const newDescriptor = descriptor as SortDescriptor;
              // If it's a new column, default to descending for numbers
              if (newDescriptor.column !== taggerSort.column) {
                const isNumericColumn = ['productsTagged', 'matched', 'not_matched'].includes(newDescriptor.column);
                newDescriptor.direction = isNumericColumn ? 'descending' : 'ascending';
              }
              setTaggerSort(newDescriptor);
            }}
          >
            <Table.Header>
              <Table.Column key="user" allowsSorting>User</Table.Column>
              <Table.Column allowsSorting key="productsTagged">Tagged</Table.Column>
              <Table.Column allowsSorting key="matched">Matched</Table.Column>
              <Table.Column allowsSorting key="not_matched">Not Matched</Table.Column>
            </Table.Header>
            <Table.Body>
              {sortData(
                taggerData,
                taggerSort.column as keyof TaggerData,
                taggerSort.direction
              ).map((item, index) => (
                <Table.Row key={index}>
                  <Table.Cell>{item.user}</Table.Cell>
                  <Table.Cell>{item.productsTagged}</Table.Cell>
                  <Table.Cell>{item.matched}</Table.Cell>
                  <Table.Cell>{item.not_matched}</Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
        )}
      </div>
      {/* Category Overview Table */}
      <div className="table-section">
        <Text h4>Category Overview</Text>
        {categoryLoading ? (
          <div className="table-loading-container">
            <Loading size="lg" />
            <Text css={{ color: "$accents6" }}>Loading category data...</Text>
          </div>
        ) : (
          <Table
            aria-label="Category analytics table"
            css={{ minWidth: "100%" }}
            sortDescriptor={categorySort}
            onSortChange={(descriptor) => {
              const newDescriptor = descriptor as SortDescriptor;
              // If it's a new column, default to descending for numbers
              if (newDescriptor.column !== categorySort.column) {
                const isNumericColumn = ['totalTagged', 'totalMatched', 'totalUnmatched', 'taggingPending'].includes(newDescriptor.column);
                newDescriptor.direction = isNumericColumn ? 'descending' : 'ascending';
              }
              setCategorySort(newDescriptor);
            }}
          >
            <Table.Header>
              <Table.Column allowsSorting key="category">Category</Table.Column>
              <Table.Column allowsSorting key="totalTagged">Tagged</Table.Column>
              <Table.Column allowsSorting key="totalMatched">Matched</Table.Column>
              <Table.Column allowsSorting key="totalUnmatched">Not Matched</Table.Column>
              <Table.Column allowsSorting key="taggingPending">Pending</Table.Column>
            </Table.Header>
            <Table.Body>
              {sortData(
                categoryData,
                categorySort.column as keyof CategoryData,
                categorySort.direction
              ).map((item, index) => (
                <Table.Row key={index}>
                  <Table.Cell>{formatCategoryName(item.category)}</Table.Cell>
                  <Table.Cell>{item.totalTagged}</Table.Cell>
                  <Table.Cell>{item.totalMatched}</Table.Cell>
                  <Table.Cell>{item.totalUnmatched}</Table.Cell>
                  <Table.Cell>{item.taggingPending}</Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
        )}
      </div>
    </div>
  );
};

export default AnalyticsDashboard; 