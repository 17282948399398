import taggerGuide from '../guides/tagger.md';
import reviewGuide from '../guides/review.md';

export type GuideType = 'tagger' | 'dashboard' | 'analytics' | 'review';

interface GuideContent {
  content: string;
  title: string;
}

const guides: Record<GuideType, GuideContent> = {
  tagger: {
    content: taggerGuide,
    title: 'Product Tagger Guide'
  },
  dashboard: {
    content: '', // Will be added when dashboard guide is created
    title: 'Dashboard Guide'
  },
  analytics: {
    content: '', // Will be added when analytics guide is created
    title: 'Analytics Guide'
  },
  review: {
    content: reviewGuide,
    title: 'Review Dashboard Guide'
  }
};

export const getGuideContent = (type: GuideType): GuideContent | null => {
  return guides[type] || null;
};

export const shouldShowGuide = (type: GuideType): boolean => {
  const hideGuideKey = `hide${type.charAt(0).toUpperCase() + type.slice(1)}Guide`;
  return localStorage.getItem(hideGuideKey) !== 'true';
};

export const setHideGuide = (type: GuideType, hide: boolean): void => {
  const hideGuideKey = `hide${type.charAt(0).toUpperCase() + type.slice(1)}Guide`;
  if (hide) {
    localStorage.setItem(hideGuideKey, 'true');
  } else {
    localStorage.removeItem(hideGuideKey);
  }
};
