import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import { ErrorBoundary } from "react-error-boundary";
import Login from "./components/Login";
import Dashboard from "./pages/Dashboard";
import { Container, Text, Button } from "@nextui-org/react";
import Callback from "./pages/ZetaCallback";
import { AuthProvider, useAuth } from "./context/AuthContext";
import ReviewPage from "./pages/review";
import TagPage from "./pages/tag";
import ProgressBarLoading from "./components/progressBarLoading";
import { SnackbarProvider } from "notistack";
import MainLayout from "./components/Layout/MainLayout";
import { MantineProvider } from "@mantine/core";
import "@mantine/core/styles.css";
import "@mantine/dates/styles.css";
import AnalyticsPage from "./pages/analytics";

const ProtectedRoute: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const { user, loading } = useAuth();
  if (loading) {
    return <ProgressBarLoading message="Fetching your profile..." />;
  }
  return user && user.email ? (
    <MainLayout>{children}</MainLayout>
  ) : (
    <Navigate to="/login" />
  );
};

const ErrorFallback: React.FC<{
  error: Error;
  resetErrorBoundary: () => void;
}> = ({ error, resetErrorBoundary }) => (
  <Container>
    <Text h1>Something went wrong</Text>
    <Text>{error.message}</Text>
    <Button onClick={resetErrorBoundary}>Try again</Button>
  </Container>
);

const App: React.FC = () => {
  return (
    <AuthProvider>
      <SnackbarProvider>
        <ErrorBoundary FallbackComponent={ErrorFallback}>
          <Router>
            <Routes>
              <Route path="/callback" element={<Callback />} />
              <Route path="/login" element={<Login />} />
              <Route path="/" element={<Navigate to="/dashboard" />} />
              <Route
                path="/dashboard/*"
                element={
                  <ProtectedRoute>
                    <Routes>
                      <Route index element={<Dashboard />} />
                      <Route path="tag" element={<TagPage />} />
                    </Routes>
                  </ProtectedRoute>
                }
              />
              <Route
                path="/review"
                element={
                  <ProtectedRoute>
                    <MantineProvider>
                      <ReviewPage />
                    </MantineProvider>
                  </ProtectedRoute>
                }
              />
              <Route
                path="/analytics"
                element={
                  <ProtectedRoute>
                    <MantineProvider>
                      <AnalyticsPage />
                    </MantineProvider>
                </ProtectedRoute>
                }
              />
              <Route path="*" element={<Navigate to="/dashboard" replace />} />
            </Routes>
          </Router>
        </ErrorBoundary>
      </SnackbarProvider>
    </AuthProvider>
  );
};

export default App;
