import axios from "axios";
import { getUser } from "../services/zitadelAuthService";

// In development, we use relative URLs that get proxied
// In production, we use the full URL from environment variable
const baseURL = process.env.NODE_ENV === 'production'
  ? process.env.REACT_APP_API_PUBLIC_URL
  : '';

const axiosWithHeader = axios.create({
  baseURL,
  timeout: 30000,
  headers: {
    'Content-Type': 'application/json',
  },
});

axiosWithHeader.interceptors.request.use(
  async (config) => {
    try {
      const user = await getUser();
      if (user && user?.profile?.email && !user.expired) {
        config.headers.Authorization = `Bearer ${user.id_token}`;
      }
      return config;
    } catch (error) {
      console.error('Error in auth interceptor:', error);
      return config;
    }
  },
  (error) => Promise.reject(error)
);

// Add response interceptor for error handling
axiosWithHeader.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response?.status === 401) {
      // Handle unauthorized access
      window.location.href = '/login';
    }
    return Promise.reject(error);
  }
);

export default axiosWithHeader;
