import axios, { AxiosError } from "axios";
import axiosWithHeader from "./interceptors";
import { useAuth } from "../context/AuthContext";

interface Product {
  product_id: string;
  category_id: string;
  channel_id: string;
  source_image_url: string;
  matches: Match[];
}

interface Match {
  product_id: string;
  category_id: string;
  channel_id: string;
  target_image_url: string;
  score: number;
}

interface GetBatchParams {
  includes?: {
    source_category_id?: string;
  };
  refresh?: boolean;
}

export const getCategories = async () => {
  try {
    const response = await axiosWithHeader.get("/api/tagging/categories");
    return response;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      const axiosError = error as AxiosError;
      if (axiosError.response?.status === 401) {
        throw new Error("Unauthorized: Please log in again");
      }
      throw new Error(`Failed to fetch categories: ${axiosError.message}`);
    }
    throw new Error("Failed to fetch categories");
  }
};

export const getProductBatch = async (params: GetBatchParams = {}) => {
  try {
    const response = await axiosWithHeader.post(
      "/api/tagging/get_batch",
      params
    );
    return response;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      const axiosError = error as AxiosError;
      if (axiosError.response?.status === 401) {
        throw new Error("Unauthorized: Please log in again");
      }
      throw new Error(`Failed to fetch product batch: ${axiosError.message}`);
    }
    throw new Error("Failed to fetch product batch");
  }
};

export const tagProduct = async (body = {}) => {
  try {
    const response = await axiosWithHeader.post(
      "/api/tagging/tag_product",
      body
    );
    return response;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      const axiosError = error as AxiosError;
      if (axiosError.response?.status === 401) {
        throw new Error("Unauthorized: Please log in again");
      }
      throw new Error(`Failed to tag product: ${axiosError.message}`);
    }
    throw new Error("Failed to tag product");
  }
};

export const completeCurrentBatch = async () => {
  try {
    await axiosWithHeader.post("/api/tagging/complete_batch");
  } catch (error) {
    if (axios.isAxiosError(error)) {
      const axiosError = error as AxiosError;
      if (axiosError.response?.status === 401) {
        throw new Error("Unauthorized: Please log in again");
      }
      throw new Error(`Failed to remove previous batch: ${axiosError.message}`);
    }
    throw new Error("Failed to remove previous batch");
  }
};

export const getReviewBatch = async (params: GetBatchParams = {}) => {
  try {
    const response = await axiosWithHeader.post(
      "/api/review/get_batch",
      params
    );
    return response;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      const axiosError = error as AxiosError;
      throw new Error(`Failed to fetch review batch: ${axiosError.message}`);
    }
    throw new Error("Failed to fetch review batch");
  }
};

export const updateMatch = async (body = {}) => {
  try {
    const response = await axiosWithHeader.post(
      "/api/review/review_product",
      body
    );
    return response;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      const axiosError = error as AxiosError;
      throw new Error(`Failed to update match: ${axiosError.message}`);
    }
    throw new Error("Failed to update match");
  }
};

export const bulkReview = async (body = {}) => {
  try {
    await axiosWithHeader.post("/api/review/bulk_review", body);
  } catch (error) {
    throw new Error("Failed to approve all");
  }
};

export const getReviewFilters = async () => {
  try {
    const response = await axiosWithHeader.get("/api/review/filters");
    return response;
  } catch (error) {
    throw new Error("Failed to fetch filters");
  }
};

export const get_user_analytics = async (params?: any) => {
  try {
    const response = await axiosWithHeader.post('/api/analytics/user_analytics', params || {});
    return response;
  } catch (error: any) {
    if (error.response?.status === 401) {
      throw new Error("Session expired, please refresh");
    }
    throw new Error(error.response?.data?.message || "Failed to load analytics data");
  }
};

export const get_category_analytics = async () => {
  try {
    const response = await axiosWithHeader.get('/api/analytics/category_analytics');
    return response;
  } catch (error: any) {
    throw new Error(error.response?.data?.message || "Failed to load category overview");
  }
};

export const get_overall_analytics = async () => {
  try {
    const response = await axiosWithHeader.get('/api/analytics/overall_analytics');
    return response;
  } catch (error: any) {
    throw new Error(error.response?.data?.message || "Failed to load overall analytics");
  }
};

// export const fetchProduct = async (
//   page: number,
//   userEmail: string
// ): Promise<{ product: Product; totalPages: number }> => {
//   try {
//     const response = await axiosWithHeader.get<{
//       product: Product;
//       totalPages: number;
//     }>(`/product`, {
//       params: { page, userEmail },
//     });
//     return response.data;
//   } catch (error) {
//     if (axios.isAxiosError(error)) {
//       const axiosError = error as AxiosError;
//       throw new Error(`Failed to fetch product: ${axiosError.message}`);
//     }
//     throw new Error("Failed to fetch product");
//   }
// };

// export const submitTag = async (
//   productId: string,
//   matchId: string,
//   userEmail: string
// ): Promise<void> => {
//   try {
//     await axiosWithHeader.post(
//       `/tagging/tag`,
//       {
//         productId,
//         matchId,
//         userEmail,
//       }
//     );
//   } catch (error) {
//     if (axios.isAxiosError(error)) {
//       const axiosError = error as AxiosError;
//       throw new Error(`Failed to submit tag: ${axiosError.message}`);
//     }
//     throw new Error("Failed to submit tag");
//   }
// };
