import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Text, Button } from '@nextui-org/react';
import KeyboardGuide from '../KeyboardGuide';
import { GuideType, shouldShowGuide } from '../../utils/guideLoader';
import './Sidebar.css';
import { useAuth } from "../../context/AuthContext";

const DashboardIcon = () => (
  <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M4 13H10C10.55 13 11 12.55 11 12V4C11 3.45 10.55 3 10 3H4C3.45 3 3 3.45 3 4V12C3 12.55 3.45 13 4 13ZM4 21H10C10.55 21 11 20.55 11 20V16C11 15.45 10.55 15 10 15H4C3.45 15 3 15.45 3 16V20C3 20.55 3.45 21 4 21ZM14 21H20C20.55 21 21 20.55 21 20V12C21 11.45 20.55 11 20 11H14C13.45 11 13 11.45 13 12V20C13 20.55 13.45 21 14 21ZM13 4V8C13 8.55 13.45 9 14 9H20C20.55 9 21 8.55 21 8V4C21 3.45 20.55 3 20 3H14C13.45 3 13 3.45 13 4Z" fill="currentColor"/>
  </svg>
);

const TaggerIcon = () => (
  <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M21.41 11.58L12.41 2.58C12.05 2.22 11.55 2 11 2H4C2.9 2 2 2.9 2 4V11C2 11.55 2.22 12.05 2.59 12.42L11.59 21.42C11.95 21.78 12.45 22 13 22C13.55 22 14.05 21.78 14.41 21.41L21.41 14.41C21.78 14.05 22 13.55 22 13C22 12.45 21.77 11.94 21.41 11.58ZM5.5 7C4.67 7 4 6.33 4 5.5C4 4.67 4.67 4 5.5 4C6.33 4 7 4.67 7 5.5C7 6.33 6.33 7 5.5 7Z" fill="currentColor"/>
  </svg>
);

const AnalyticsIcon = () => (
  <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M19 3H5C3.9 3 3 3.9 3 5V19C3 20.1 3.9 21 5 21H19C20.1 21 21 20.1 21 19V5C21 3.9 20.1 3 19 3ZM9 17H7V10H9V17ZM13 17H11V7H13V17ZM17 17H15V13H17V17Z" fill="currentColor"/>
  </svg>
);


const ReviewIcon = () => (
  <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M14.06 9.02L14.98 9.94L5.92 19H5V18.08L14.06 9.02ZM17.66 3C17.41 3 17.15 3.1 16.96 3.29L15.13 5.12L18.88 8.87L20.71 7.04C21.1 6.65 21.1 6.02 20.71 5.63L18.37 3.29C18.17 3.09 17.92 3 17.66 3ZM14.06 6.19L3 17.25V21H6.75L17.81 9.94L14.06 6.19Z" fill="currentColor"/>
  </svg>
);


const CollapseIcon = ({ collapsed }: { collapsed: boolean }) => (
  <svg 
    width="20" 
    height="20" 
    viewBox="0 0 24 24" 
    fill="none" 
    xmlns="http://www.w3.org/2000/svg"
    style={{ transform: collapsed ? 'rotate(180deg)' : 'none' }}
  >
    <path d="M15.41 7.41L14 6L8 12L14 18L15.41 16.59L10.83 12L15.41 7.41Z" fill="currentColor"/>
  </svg>
);

const KeyboardIcon = () => (
  <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M20 5H4C2.897 5 2 5.897 2 7V17C2 18.103 2.897 19 4 19H20C21.103 19 22 18.103 22 17V7C22 5.897 21.103 5 20 5ZM4 17V7H20L20.002 17H4Z" fill="currentColor"/>
    <path d="M5 8H7V10H5V8ZM8 8H10V10H8V8ZM11 8H13V10H11V8ZM14 8H16V10H14V8ZM17 8H19V10H17V8ZM5 11H7V13H5V11ZM8 11H10V13H8V11ZM11 11H13V13H11V11ZM14 11H16V13H14V11ZM17 11H19V13H17V11ZM8 14H16V16H8V14Z" fill="currentColor"/>
  </svg>
);

const Sidebar: React.FC = () => {
  const [collapsed, setCollapsed] = useState(false);
  const [showKeyboardGuide, setShowKeyboardGuide] = useState(false);
  const location = useLocation();
  const { user } = useAuth();

  // Check if user has reviewer role
  const isReviewer = user?.role?.includes("reviewer") || user?.role?.includes("admin");

  const currentGuideType: GuideType | null = (() => {
    switch (location.pathname) {
      case '/dashboard/tag':
        return 'tagger';
      case '/':
        return 'dashboard';
      // case '/analytics':
      //   return 'analytics';
      case '/review':
        return 'review';
      default:
        return null;
    }
  })();

  // Show guide automatically when entering a page if it hasn't been hidden
  useEffect(() => {
    if (currentGuideType && shouldShowGuide(currentGuideType)) {
      setShowKeyboardGuide(true);
    }
  }, [currentGuideType]);

  // Auto-collapse when Product Tagger or Review Dashboard is selected
  useEffect(() => {
    if (location.pathname === '/dashboard/tag' || location.pathname === '/review') {
      setCollapsed(true);
    }
  }, [location.pathname]);

  const handleDontShowAgain = (dontShow: boolean) => {
    // This is now handled by the guideLoader utility
  };

  // Filter menu items based on user role
  const menuItems = [
    { path: '/', label: 'Dashboard', icon: <DashboardIcon /> },
    { path: '/dashboard/tag', label: 'Product Tagger', icon: <TaggerIcon /> },
    // Only show analytics and review options if user is a reviewer/admin
    ...(isReviewer ? [
      { path: '/analytics', label: 'Analytics', icon: <AnalyticsIcon /> },
      { path: '/review', label: 'Review', icon: <ReviewIcon /> }
    ] : []),
  ];

  return (
    <div style={{
      width: collapsed ? '64px' : '200px',
      height: 'calc(100vh - 76px)',
      backgroundColor: '#f8f9fa',
      padding: '12px 8px',
      borderRight: '1px solid #eaeaea',
      transition: 'width 0.2s ease',
      position: 'relative',
      display: 'flex',
      flexDirection: 'column',
    }}>
      <Button
        auto
        light
        css={{
          position: 'absolute',
          right: collapsed ? '50%' : '-12px',
          top: '12px',
          transform: collapsed ? 'translateX(50%)' : 'none',
          minWidth: '24px',
          height: '24px',
          padding: 0,
          borderRadius: '12px',
          background: '#fff',
          border: '1px solid #eaeaea',
          cursor: 'pointer',
          '&:hover': {
            background: '#f6f6f6',
          }
        }}
        onClick={() => setCollapsed(!collapsed)}
      >
        <CollapseIcon collapsed={collapsed} />
      </Button>

      <div style={{ marginTop: '40px', flex: 1 }}>
        {menuItems.map((item) => (
          <Link 
            key={item.path} 
            to={item.path}
            style={{ textDecoration: 'none' }}
            onClick={() => {
              if (item.path === '/dashboard/tag' || item.path === '/review') {
                setCollapsed(true);
              }
            }}
          >
            <div 
              className="sidebar-item"
              style={{
                padding: collapsed ? '12px' : '12px 16px',
                marginBottom: '8px',
                borderRadius: '8px',
                backgroundColor: location.pathname === item.path ? '#0072F5' : 'transparent',
                cursor: 'pointer',
                display: 'flex',
                alignItems: 'center',
                gap: '12px',
                justifyContent: collapsed ? 'center' : 'flex-start',
              }}
            >
              <div style={{ 
                color: location.pathname === item.path ? 'white' : '#666',
              }}>
                {item.icon}
              </div>
              {!collapsed && (
                <Text 
                  css={{ 
                    color: location.pathname === item.path ? 'white' : '#666',
                    fontWeight: location.pathname === item.path ? '600' : '400',
                  }}
                >
                  {item.label}
                </Text>
              )}
            </div>
          </Link>
        ))}
      </div>

      {currentGuideType && (
        <div style={{ marginTop: 'auto', padding: '0 0 8px 0' }}>
          <div
            className="sidebar-item"
            onClick={() => setShowKeyboardGuide(true)}
            style={{
              padding: collapsed ? '12px' : '12px 16px',
              borderRadius: '8px',
              cursor: 'pointer',
              display: 'flex',
              alignItems: 'center',
              gap: '12px',
              justifyContent: collapsed ? 'center' : 'flex-start',
              color: '#666',
              transition: 'all 0.2s ease',
            }}
          >
            <div style={{ color: '#666' }}>
              <KeyboardIcon />
            </div>
            {!collapsed && (
              <Text 
                css={{ 
                  color: '#666',
                  fontWeight: '400',
                }}
              >
                Keyboard Shortcuts
              </Text>
            )}
          </div>
        </div>
      )}

      {currentGuideType && (
        <KeyboardGuide 
          isOpen={showKeyboardGuide}
          onClose={() => setShowKeyboardGuide(false)}
          onDontShowAgain={handleDontShowAgain}
          guideType={currentGuideType}
        />
      )}
    </div>
  );
};

export default Sidebar;
