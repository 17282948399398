import React from "react";
import ProductTagger from "../components/ProductTagger";
import { useAuth } from "../context/AuthContext";

const TagPage: React.FC = () => {
  const { user } = useAuth();
  return <ProductTagger user={user} />;
};

export default TagPage;
