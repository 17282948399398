import React from "react";
import { useLocation } from "react-router-dom";
import Header from "./Header";
import Sidebar from "./Sidebar";
import { CategoryProvider } from "../../context/CategoryContext";

interface MainLayoutProps {
  children: React.ReactNode;
}

const MainLayout: React.FC<MainLayoutProps> = ({ children }) => {
  const location = useLocation();
  const isReviewPage = location.pathname === "/review";
  const isAnalyticsPage = location.pathname === "/analytics";

  return (
    <CategoryProvider>
      <div style={{ minHeight: "100vh" }}>
        <Header 
          showCategory={!isReviewPage && !isAnalyticsPage} 
          showRefresh={!isReviewPage && !isAnalyticsPage} 
        />
        <div
          style={{
            display: "flex",
            height: "calc(100vh - 76px)", // Navbar height is 76px
          }}
        >
          <Sidebar />
          <main
            style={{
              flex: 1,
              overflow: "auto",
              backgroundColor: "#fafafa",
            }}
          >
            {children}
          </main>
        </div>
      </div>
    </CategoryProvider>
  );
};

export default MainLayout;
