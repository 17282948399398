import React, { useState } from 'react';
import { Modal, Button, Text, Checkbox, Container } from '@nextui-org/react';
import ReactMarkdown from 'react-markdown';
import { GuideType, getGuideContent, setHideGuide } from '../utils/guideLoader';
import '../styles/markdown.css';

interface KeyboardGuideProps {
  isOpen: boolean;
  onClose: () => void;
  onDontShowAgain: (dontShow: boolean) => void;
  guideType: GuideType;
}

const KeyboardGuide: React.FC<KeyboardGuideProps> = ({
  isOpen,
  onClose,
  onDontShowAgain,
  guideType
}) => {
  const [dontShowAgain, setDontShowAgain] = useState(false);
  const guide = getGuideContent(guideType);

  if (!guide) return null;

  const handleClose = () => {
    if (dontShowAgain) {
      setHideGuide(guideType, true);
      onDontShowAgain(true);
    }
    onClose();
  };

  return (
    <Modal
      closeButton
      aria-labelledby="keyboard-guide-modal"
      open={isOpen}
      onClose={handleClose}
      width="600px"
    >
      <Modal.Header>
        <Text h3>{guide.title}</Text>
      </Modal.Header>
      <Modal.Body css={{ paddingTop: '0' }}>
        <Container 
          className="markdown-content"
          css={{
            padding: '20px 0',
            '& h1': { fontSize: '1.5em', marginBottom: '1em' },
            '& h2': { fontSize: '1.2em', marginTop: '1.5em', marginBottom: '0.5em' },
            '& p': { marginBottom: '1em', lineHeight: '1.5' },
            '& ul': { paddingLeft: '1.5em', marginBottom: '1em' },
            '& li': { marginBottom: '0.5em' },
            '& code': {
              backgroundColor: '#f4f4f4',
              padding: '2px 4px',
              borderRadius: '4px',
              fontSize: '0.9em'
            }
          }}
        >
          <ReactMarkdown>{guide.content}</ReactMarkdown>
        </Container>
        <Checkbox
          isSelected={dontShowAgain}
          onChange={setDontShowAgain}
          size="sm"
          css={{ paddingTop: '12px' }}
        >
          Don't show this guide again
        </Checkbox>
      </Modal.Body>
      <Modal.Footer>
        <Button auto flat onPress={handleClose}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default KeyboardGuide;
