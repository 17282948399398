import React, { useMemo, useState, useEffect } from "react";
import { Container, Button, Text, Spacer, Dropdown, Loading } from "@nextui-org/react";
import { useNavigate, useLocation } from "react-router-dom";
import { useAuth } from "../context/AuthContext";
// import { getCategories } from "../api/dashboard";
import { useSnackbar } from 'notistack';

import "../styles/dashboard.css";

const Dashboard: React.FC = () => {
  // const [selectedCategory, setSelectedCategory] = useState<string | null>(null);
  // const [categoryError, setCategoryError] = useState<string | null>(null);
  // const [categoriesLoading, setCategoriesLoading] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();
  const { user, signOut } = useAuth();
  const { enqueueSnackbar } = useSnackbar();

  // permissions
  const canTag = useMemo(() => {
    if (!user || !user.role) return false;
    const canAccess = ["admin", "tagger", "reviewer"];
    return user.role.some((role: string) => canAccess.includes(role));
  }, [user]);
  const canReview = useMemo(() => {
    if (!user || !user.role) return false;
    const canAccess = ["admin", "reviewer"];
    return user.role.some((role: string) => canAccess.includes(role));
  }, [user]);

  // handlers
  const handleLogout = async () => {
    await signOut();
    navigate("/login");
  };

  const handleModeSelect = (selectedMode: "review" | "tag") => {
    navigate(`/dashboard/${selectedMode}`);
  };

  useEffect(() => {
    const message = (location.state as any)?.message;
    if (message) {
      enqueueSnackbar(message, { 
        variant: 'info',
        autoHideDuration: 4000
      });
      // Clear the message from location state
      window.history.replaceState({}, document.title);
    }
  }, [location, enqueueSnackbar]);

  if (!user) {
    return (
      <Container>
        <Text color="error">User data not available. Please log in again.</Text>
        <Button onClick={() => navigate("/login")}>Go to Login</Button>
      </Container>
    );
  }

  return (
    <Container>
      <Text h3>Welcome, {user.email}</Text>
      <Spacer y={1} />
      <Button onClick={handleLogout}>Logout</Button>
    </Container>
  );
};

export default Dashboard;
