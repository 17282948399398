import React, { useState, useEffect, useCallback, memo } from "react";
import {
  Container,
  Button,
  Text,
  Loading,
  Dropdown,
  Popover,
  Modal as NextModal,
} from "@nextui-org/react";
import ProductCard from "./ProductCard";
import { useSnackbar } from "notistack";
import { DatePicker } from "@mantine/dates";
import "@mantine/core/styles.css";
import { FixedSizeList as List } from "react-window";
import AutoSizer from "react-virtualized-auto-sizer";

import {
  BATCH_EXPIRED_API_KEY,
  FAILED_API_MSG,
  PRODUCT_DASH_CONST,
} from "../constants/constant";

import {
  completeCurrentBatch,
  getReviewBatch,
  updateMatch,
  bulkReview,
  getReviewFilters,
} from "../api/dashboard";

import "../styles/productTagger.css";
import "../styles/reviewDashboard.css";
import { formatCategoryName } from "../utils/helpers";

let searchNext = "";

const ReviewDashboard: React.FC = memo(() => {
  const [products, setProducts] = useState<any>([]);
  const [currentProductNumber, setCurrentProductNumber] = useState<number>(0);
  const [loading, setLoading] = useState(false);
  const [filters, setFilters] = useState<any>({});
  const [error, setError] = useState<string | null>(null);
  const [selectedCategory, setSelectedCategory] = useState<string | null>(null);
  const { enqueueSnackbar } = useSnackbar();
  const [reviewerSelectedMatches, setReviewerSelectedMatches] = useState<
    number[]
  >([]);
  const [selectedUser, setSelectedUser] = useState<string>("");
  const [selectedDateRange, setSelectedDateRange] = useState<
    [Date | null, Date | null]
  >([null, null]);
  const [isDatePopoverOpen, setIsDatePopoverOpen] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [selectedMatch, setSelectedMatch] = useState<number>(-1);
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const [showBulkConfimation, setShowBulkConfirmation] = useState(false);
  const [bulkAction, setBulkAction] = useState<string | null>(null);

  useEffect(() => {
    if (products.length && !reviewerSelectedMatches.length) {
      setReviewerSelectedMatches((prev) => {
        const newMatches = Array(products.length).fill(-1);
        return newMatches;
      });
    }
  }, [products]);

  const handleApproveAll = async () => {
    try {
      await bulkReview({
        action: "approve",
        includes: {
          source_category_id: selectedCategory,
          ...(selectedUser && { tagged_by: selectedUser }),
          ...(selectedDateRange[0] &&
            selectedDateRange[1] && {
              tagged_timestamp: {
                start: selectedDateRange[0].toLocaleDateString("en-CA"),
                end: selectedDateRange[1].toLocaleDateString("en-CA"),
              },
            }),
        },
      }).then(async () => {
        loadProduct(true);
        enqueueSnackbar("Products approved successfully", {
          variant: "success",
          anchorOrigin: { vertical: "top", horizontal: "right" },
          autoHideDuration: 2000,
        });
      });
    } catch (error: any) {
      enqueueSnackbar(error.message || "Failed to approve all", {
        variant: "error",
        anchorOrigin: { vertical: "top", horizontal: "right" },
        autoHideDuration: 2000,
      });
    }
  };

  const handleRevertTagging = async () => {
    try {
      await bulkReview({
        action: "reject",
        includes: {
          source_category_id: selectedCategory,
        },
      }).then(() => {
        loadProduct(true);
        enqueueSnackbar("Products reverted successfully", {
          variant: "success",
          anchorOrigin: { vertical: "top", horizontal: "right" },
          autoHideDuration: 2000,
        });
      });
    } catch (error: any) {
      enqueueSnackbar(error.message || "Failed to revert tagging", {
        variant: "error",
        anchorOrigin: { vertical: "top", horizontal: "right" },
        autoHideDuration: 2000,
      });
    }
  };

  useEffect(() => {
    const handleKeyPress = (event: KeyboardEvent) => {
      if (showConfirmation && event.key === "Escape") {
        setShowConfirmation(false);
        return;
      }

      if (showConfirmation) return; // Don't handle other keys when confirmation is shown

      if (event.key === "Enter" && !handleDisable()) {
        handleUpdateMatch();
        return;
      }

      if (
        +event.key > 0 &&
        +event.key <= products[currentProductNumber]?.matches.length
      ) {
        const newMatch = +event.key - 1;
        setSelectedMatch(newMatch);
        setReviewerSelectedMatches((prev) => {
          const newMatches = [...prev];
          newMatches[currentProductNumber] = newMatch;
          return newMatches;
        });
      }
      if (event.key === "0") {
        setSelectedMatch(-1);
        setReviewerSelectedMatches((prev) => {
          const newMatches = [...prev];
          newMatches[currentProductNumber] = -1;
          return newMatches;
        });
        handleNoMatch();
      }
      switch (event.key) {
        case "ArrowUp":
          event.preventDefault();
          if (
            reviewerSelectedMatches[currentProductNumber] !== -1 &&
            products[currentProductNumber]?.tagged_product_id !==
              products[currentProductNumber]?.matches[
                reviewerSelectedMatches[currentProductNumber]
              ]?.match_product_id
          ) {
            setShowConfirmation(true);
          } else {
            const newIndex = Math.max(0, currentProductNumber - 1);
            setCurrentProductNumber(newIndex);
            setSelectedMatch(-1);

            // Find the list container and calculate scroll position
            const listContainer = document.querySelector(".product-list");
            if (listContainer) {
              const itemHeight = 80; // height of each item
              const containerHeight = listContainer.clientHeight;
              const targetScrollTop = newIndex * itemHeight;
              const currentScrollTop = listContainer.scrollTop;

              // If item is out of view, scroll to it
              if (
                targetScrollTop < currentScrollTop ||
                targetScrollTop >
                  currentScrollTop + containerHeight - itemHeight
              ) {
                listContainer.scrollTo({
                  top: targetScrollTop - containerHeight / 2 + itemHeight / 2,
                  behavior: "smooth",
                });
              }
            }
          }
          break;
        case "ArrowDown":
          event.preventDefault();
          if (
            reviewerSelectedMatches[currentProductNumber] !== -1 &&
            products[currentProductNumber]?.tagged_product_id !==
              products[currentProductNumber]?.matches[
                reviewerSelectedMatches[currentProductNumber]
              ]?.match_product_id
          ) {
            setShowConfirmation(true);
          } else {
            const newIndex = Math.min(
              products.length - 1,
              currentProductNumber + 1
            );
            setCurrentProductNumber(newIndex);
            setSelectedMatch(-1);

            // Find the list container and calculate scroll position
            const listContainer = document.querySelector(".product-list");
            if (listContainer) {
              const itemHeight = 80; // height of each item
              const containerHeight = listContainer.clientHeight;
              const targetScrollTop = newIndex * itemHeight;
              const currentScrollTop = listContainer.scrollTop;

              // If item is out of view, scroll to it
              if (
                targetScrollTop < currentScrollTop ||
                targetScrollTop >
                  currentScrollTop + containerHeight - itemHeight
              ) {
                listContainer.scrollTo({
                  top: targetScrollTop - containerHeight / 2 + itemHeight / 2,
                  behavior: "smooth",
                });
              }
            }
          }
          break;
        case "Escape":
          if (showConfirmation) setShowConfirmation(false);
          break;
      }
    };

    window.addEventListener("keydown", handleKeyPress);
    return () => window.removeEventListener("keydown", handleKeyPress);
  }, [
    products.length,
    currentProductNumber,
    reviewerSelectedMatches,
    showConfirmation,
  ]);

  const handleUpdateMatch = useCallback(async () => {
    try {
      const currentProduct = products[currentProductNumber];
      const matchIndex = reviewerSelectedMatches[currentProductNumber];

      if (matchIndex === -1) return;

      const params = {
        source_product_id: currentProduct?.source_product_id,
        match_product_id: currentProduct?.matches[matchIndex]?.match_product_id,
        status: PRODUCT_DASH_CONST.REVIEW_STATUS.ACCEPT,
        _id: currentProduct?._id,
      };

      await updateMatch(params);

      setProducts((prev: any) => {
        const newProducts = [...prev];
        newProducts[currentProductNumber] = {
          ...newProducts[currentProductNumber],
          tagged_product_id:
            currentProduct?.matches[matchIndex]?.match_product_id,
        };
        return newProducts;
      });

      setSelectedMatch(-1);

      enqueueSnackbar("Match updated successfully", {
        variant: "success",
        anchorOrigin: { vertical: "top", horizontal: "right" },
        autoHideDuration: 2000,
      });

      if (currentProductNumber === products.length - 1) {
        await loadProduct(true);
      } else {
        setCurrentProductNumber((prev) => prev + 1);
      }
    } catch (error: any) {
      enqueueSnackbar(error.message || "Failed to update match", {
        variant: "error",
        anchorOrigin: { vertical: "top", horizontal: "right" },
        autoHideDuration: 2000,
      });
    }
  }, [currentProductNumber, products, reviewerSelectedMatches]);

  const loadProduct = useCallback(
    async (
      refresh: boolean = false,
      categoryOverride?: string,
      userOverride?: string,
      dateOverride?: [Date | null, Date | null]
    ) => {
      const categoryToUse = categoryOverride || selectedCategory;
      if (!categoryToUse) return; // Early return if no category is selected

      setLoading(true);
      setError(null);
      try {
        const params: {} = {
          includes: {
            source_category_id: categoryToUse,
            ...(userOverride
              ? { tagged_by: userOverride }
              : selectedUser && { tagged_by: selectedUser }),
            ...(dateOverride
              ? dateOverride[0] &&
                dateOverride[1] && {
                  tagged_timestamp: {
                    start: dateOverride[0].toLocaleDateString("en-CA"),
                    end: dateOverride[1].toLocaleDateString("en-CA"),
                  },
                }
              : selectedDateRange[0] &&
                selectedDateRange[1] && {
                  tagged_timestamp: {
                    start: selectedDateRange[0].toLocaleDateString("en-CA"),
                    end: selectedDateRange[1].toLocaleDateString("en-CA"),
                  },
                }),
          },
          refresh,
        };
        const productsBatch: any = await getReviewBatch(params);
        searchNext = productsBatch?.data?.next_search_after;
        setProducts(productsBatch?.data?.products || []);
        setCurrentProductNumber(productsBatch?.data?.itr || 0);
      } catch (error: any) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    },
    [selectedUser, selectedDateRange, selectedCategory, filters]
  );

  useEffect(() => {
    const getFilters = async () => {
      const res = await getReviewFilters();
      setFilters(res.data);
    };
    getFilters();
  }, []);

  useEffect(() => {
    if (Object.keys(filters).length && filters.categories?.data?.length) {
      const firstCategory = filters.categories.data[0]?.category_id;
      if (firstCategory) {
        // Only set category and load if there's a valid category
        setSelectedCategory(firstCategory);
        loadProduct(true, firstCategory);
      }
    }
  }, [filters]);

  useEffect(() => {
    if (selectedDateRange[0] && selectedDateRange[1] && selectedCategory) {
      // Add selectedCategory check
      loadProduct(true);
    }
  }, [selectedDateRange]);

  const handleNoMatch = async () => {
    try {
      await updateMatch({
        source_product_id: products[currentProductNumber]?.source_product_id,
        match_product_id: "",
        status: PRODUCT_DASH_CONST.REVIEW_STATUS.REJECT,
        _id: products[currentProductNumber]?._id,
      }).then((res) => {
        setReviewerSelectedMatches((prev) => {
          const newMatches = [...prev];
          newMatches[currentProductNumber] = -1;
          return newMatches;
        });
        setProducts((prev: any) => {
          const newProducts = [...prev];
          newProducts[currentProductNumber] = {
            ...newProducts[currentProductNumber],
            tagged_product_id: "",
          };
          return newProducts;
        });
        enqueueSnackbar("Tagged No Match", {
          variant: "success",
          anchorOrigin: { vertical: "top", horizontal: "right" },
          autoHideDuration: 2000,
        });
      });
      if (currentProductNumber === products.length - 1) {
        await completeCurrentBatch();
        await loadProduct(true);
      } else {
        setCurrentProductNumber((prev) => prev + 1);
      }
    } catch (error: any) {
      enqueueSnackbar(error.message || "Failed to reject product", {
        variant: "error",
        anchorOrigin: { vertical: "top", horizontal: "right" },
        autoHideDuration: 2000,
      });
    }
  };

  const handleDisable = useCallback(() => {
    return (
      !reviewerSelectedMatches.length ||
      reviewerSelectedMatches[currentProductNumber] === -1 ||
      products[currentProductNumber]?.matches[
        reviewerSelectedMatches[currentProductNumber]
      ]?.match_product_id === products[currentProductNumber]?.tagged_product_id
    );
  }, [reviewerSelectedMatches, products, currentProductNumber]);

  const handleScroll = useCallback(
    async (event: any) => {
      if (!isLoadingMore && searchNext) {
        const { scrollDirection, scrollOffset } = event;
        const isNearEnd = products.length - scrollOffset / 80 < 10; // 10 items from end

        if (scrollDirection === "forward" && scrollOffset > 0 && isNearEnd) {
          setIsLoadingMore(true);
          try {
            const params: {} = {
              includes: {
                source_category_id: selectedCategory,
                ...(selectedUser && { tagged_by: selectedUser }),
                ...(selectedDateRange[0] &&
                  selectedDateRange[1] && {
                    tagged_timestamp: {
                      start: selectedDateRange[0].toLocaleDateString("en-CA"),
                      end: selectedDateRange[1].toLocaleDateString("en-CA"),
                    },
                  }),
              },
              search_after: searchNext,
              refresh: false,
            };
            const productsBatch: any = await getReviewBatch(params);
            searchNext = productsBatch?.data?.next_search_after;

            // Update reviewer selected matches array to match new products length
            setReviewerSelectedMatches((prev) => {
              const newMatches = [...prev];
              const additionalMatches = Array(
                productsBatch?.data?.products?.length || 0
              ).fill(-1);
              return [...newMatches, ...additionalMatches];
            });

            setProducts((prev: any) => [
              ...prev,
              ...(productsBatch?.data?.products || []),
            ]);
          } catch (error: any) {
            enqueueSnackbar(error.message || FAILED_API_MSG, {
              variant: "error",
              anchorOrigin: { vertical: "top", horizontal: "right" },
              autoHideDuration: 2000,
            });
          } finally {
            setIsLoadingMore(false);
          }
        }
      }
    },
    [isLoadingMore, selectedCategory, selectedUser, selectedDateRange]
  );

  const Row = useCallback(
    ({ index, style }: { index: number; style: React.CSSProperties }) => {
      const product = products[index];
      return (
        <div
          style={{
            ...style,
            height: "80px",
            padding: "8px",
            boxSizing: "border-box",
          }}
          key={product.source_product_id}
          className={`product-list-item ${
            index === currentProductNumber ? "active" : ""
          }`}
          onClick={() => setCurrentProductNumber(index)}
        >
          <img
            src={product.source_feature_image_s3}
            alt={product.source_product_name}
            className="product-thumbnail"
          />
          <div className="product-info">
            <Text
              small
              css={{
                overflow: "hidden",
                textOverflow: "ellipsis",
                display: "-webkit-box",
                WebkitLineClamp: 1,
                WebkitBoxOrient: "vertical",
              }}
            >
              {product.source_product_name}
            </Text>
            <Text small css={{ color: "$accents7" }}>
              {product.tagged_by || "User"}
            </Text>
          </div>
        </div>
      );
    },
    [currentProductNumber, products]
  );

  if (loading) {
    return <Loading>Loading products...</Loading>;
  }

  if (error) {
    return (
      <Container>
        <Text color="error">{error}</Text>
        <Button onClick={() => loadProduct(true)}>Retry</Button>
      </Container>
    );
  }

  return (
    <>
      <div className="dashboard-container">
        <div className="main-content">
          <div className={`sidebar`}>
            <div
              className="review-dashboard-action-buttons"
              style={{ marginBottom: "10px" }}
            >
              <Button
                size="sm"
                color="primary"
                disabled={products.length === 0}
                onClick={() => {
                  setBulkAction("approve");
                  setShowBulkConfirmation(true);
                }}
              >
                Approve All
              </Button>
              <Button
                size="sm"
                color="primary"
                bordered
                disabled={products.length === 0}
                onClick={() => {
                  setBulkAction("reject");
                  setShowBulkConfirmation(true);
                }}
              >
                Revert Tagging
              </Button>
            </div>
            {products.length ? (
              <div className="review-dashboard-products-queue">
                <Text h4>
                  Products Queue{" "}
                  <span className="shortcut-hint">(↑↓ to navigate)</span>
                </Text>
                <div style={{ height: "calc(100vh - 240px)" }}>
                  <AutoSizer>
                    {({ height, width }: { height: number; width: number }) => (
                      <List
                        height={height}
                        itemCount={products.length}
                        itemSize={80}
                        width={width}
                        onScroll={handleScroll}
                        className="product-list"
                      >
                        {Row}
                      </List>
                    )}
                  </AutoSizer>
                </div>
                {isLoadingMore && <Loading size="sm">Loading more...</Loading>}
              </div>
            ) : (
              <div>
                <Text>No products available for review</Text>
              </div>
            )}
          </div>

          <div className="review-dashboard-product-matches-container">
            {filters && (
              <div
                className="filter-section"
                style={{
                  padding: "10px 30px",
                  background: "#f5f5f5",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <div className="filters">
                  <div className="review-category-filter">
                    <Dropdown>
                      <Dropdown.Button flat>
                        {!filters.categories ? (
                          <Loading size="sm" />
                        ) : filters.categories?.data.find((cat: any) => {
                            return cat.category_id == selectedCategory;
                          }) ? (
                          formatCategoryName(
                            filters.categories?.data.find(
                              (cat: any) => cat.category_id == selectedCategory
                            ).category_name
                          )
                        ) : (
                          "Select Category"
                        )}
                      </Dropdown.Button>
                      <Dropdown.Menu
                        aria-label="Category selection"
                        selectedKeys={"single"}
                        disallowEmptySelection
                        onAction={(key) => {
                          setSelectedCategory(key as string);
                          loadProduct(true, key as string);
                        }}
                      >
                        {filters.categories?.data.map((category: any) => {
                          return (
                            <Dropdown.Item key={category.category_id}>
                              {formatCategoryName(category.category_name)}
                            </Dropdown.Item>
                          );
                        })}
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>

                  <div className="user-filter">
                    <Dropdown>
                      <Dropdown.Button flat>
                        {selectedUser || "Select User"}
                      </Dropdown.Button>
                      <Dropdown.Menu
                        aria-label="User selection"
                        selectionMode="multiple"
                        onAction={(key: any) => {
                          if (key === "clear") {
                            setSelectedUser("");
                            loadProduct(true, undefined, "");
                          } else {
                            setSelectedUser(key);
                            loadProduct(true, undefined, key);
                          }
                        }}
                      >
                        <Dropdown.Item key="clear" css={{ color: "$error" }}>
                          Clear Selection
                        </Dropdown.Item>
                        {filters.taggers?.data.map((user: any) => {
                          return (
                            <Dropdown.Item key={user.user_id}>
                              {user.user_id}
                            </Dropdown.Item>
                          );
                        })}
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>

                  <div className="date-filter">
                    {filters.date_ranges && (
                      <Popover
                        isOpen={isDatePopoverOpen}
                        onOpenChange={setIsDatePopoverOpen}
                        placement="bottom"
                      >
                        <Popover.Trigger>
                          <Button flat>
                            {selectedDateRange[0] && selectedDateRange[1]
                              ? `${selectedDateRange[0].toLocaleDateString()} - ${selectedDateRange[1].toLocaleDateString()}`
                              : "Select Date Range"}
                          </Button>
                        </Popover.Trigger>
                        <Popover.Content>
                          <div style={{ padding: "1rem" }}>
                            <DatePicker
                              type="range"
                              value={selectedDateRange}
                              onChange={(dates) => {
                                if (Array.isArray(dates)) {
                                  const [start, end] = dates;
                                  setSelectedDateRange([start, end]);
                                  if (start && end) {
                                    setIsDatePopoverOpen(false);
                                    loadProduct(true, undefined, undefined, [
                                      start,
                                      end,
                                    ]);
                                  }
                                }
                              }}
                            />
                            {selectedDateRange[0] && selectedDateRange[1] && (
                              <Button
                                size="sm"
                                color="error"
                                light
                                css={{ marginTop: "8px" }}
                                onClick={() => {
                                  setSelectedDateRange([null, null]);
                                  setIsDatePopoverOpen(false);
                                  loadProduct(true, undefined, undefined, [
                                    null,
                                    null,
                                  ]);
                                }}
                              >
                                Clear Dates
                              </Button>
                            )}
                          </div>
                        </Popover.Content>
                      </Popover>
                    )}
                  </div>
                </div>
              </div>
            )}

            {products.length ? (
              <div className="reivew-dashboard-product-display-section">
                <div className="source-product">
                  <Text h4>Product Under Review</Text>
                  <ProductCard
                    imgURL={
                      products[currentProductNumber]?.source_feature_image_s3
                    }
                    name={products[currentProductNumber]?.source_product_name}
                    mrp={products[currentProductNumber]?.source_mrp}
                    pdpURL={products[currentProductNumber]?.source_pdp_url}
                    brand={products[currentProductNumber]?.source_brand}
                  />
                  {!products[currentProductNumber]?.tagged_product_id && (
                    <div
                      style={{
                        padding: "12px",
                        backgroundColor: "#fff0f1",
                        border: "1px solid #fdd1d7",
                        borderRadius: "4px",
                        marginTop: "8px",
                      }}
                    >
                      <Text css={{ color: "#F31260", margin: "0px" }}>
                        No Match found for this product
                      </Text>
                    </div>
                  )}
                </div>

                <div className="matches-container">
                  {products[currentProductNumber]?.matches.map(
                    (match: any, index: number) => {
                      return (
                        <div
                          key={match.match_product_id}
                          className="match-item"
                        >
                          <ProductCard
                            imgURL={match.match_feature_image_s3}
                            name={match.match_product_name}
                            mrp={match.match_mrp}
                            pdpURL={match.match_pdp_url}
                            brand={match.match_brand}
                            highlighted={
                              reviewerSelectedMatches.length &&
                              reviewerSelectedMatches[currentProductNumber] !==
                                -1
                                ? products[currentProductNumber]?.matches[
                                    reviewerSelectedMatches[
                                      currentProductNumber
                                    ]
                                  ].match_product_id === match.match_product_id
                                : products[currentProductNumber]
                                    ?.tagged_product_id ===
                                  match.match_product_id
                            }
                            serialNumber={index + 1}
                          />
                        </div>
                      );
                    }
                  )}
                </div>
                <div className={`bottom-action-bar`}>
                  <div className="action-buttons">
                    <Button color="error" onClick={handleNoMatch}>
                      No Match Found <span className="shortcut-hint">(0)</span>
                    </Button>
                    <Button
                      color="primary"
                      disabled={handleDisable()}
                      onClick={handleDisable() ? () => {} : handleUpdateMatch}
                    >
                      Update <span className="shortcut-hint">(Enter)</span>
                    </Button>
                  </div>
                </div>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
      {showConfirmation && (
        <NextModal
          open={showConfirmation}
          onClose={() => setShowConfirmation(false)}
          aria-labelledby="confirmation-modal"
          className="confirmation-modal"
        >
          <div className="modal-content">
            <div className="modal-header">
              <Text h4>Confirmation</Text>
            </div>
            <div className="modal-body">
              <Text>
                Please save the changes before proceeding to the next product
              </Text>
            </div>
            <div className="modal-footer">
              <Button
                auto
                flat
                css={{
                  backgroundColor: "#f5f5f5",
                  color: "grey",
                }}
                onPress={() => {
                  setShowConfirmation(false);
                }}
              >
                Cancel
              </Button>
              <Button
                auto
                color="primary"
                onPress={async () => {
                  await handleUpdateMatch();
                  setShowConfirmation(false);
                }}
              >
                Confirm
              </Button>
            </div>
          </div>
        </NextModal>
      )}
      {showBulkConfimation && (
        <NextModal
          open={showBulkConfimation}
          onClose={() => setShowBulkConfirmation(false)}
          aria-labelledby="confirmation-modal"
          className="confirmation-modal"
        >
          <div className="modal-content">
            <div className="modal-header">
              <Text h4>Confirmation</Text>
            </div>
            <div className="modal-body">
              <Text>Are you sure you want to {bulkAction} all products?</Text>
            </div>
            <div className="modal-footer">
              <Button
                auto
                flat
                css={{
                  backgroundColor: "#f5f5f5",
                  color: "grey",
                }}
                bordered={false}
                onPress={() => {
                  setShowBulkConfirmation(false);
                }}
              >
                Cancel
              </Button>
              <Button
                auto
                color={bulkAction === "approve" ? "primary" : "error"}
                onPress={async () => {
                  if (bulkAction === "approve") {
                    await handleApproveAll();
                  } else {
                    await handleRevertTagging();
                  }
                  setShowBulkConfirmation(false);
                }}
              >
                Confirm
              </Button>
            </div>
          </div>
        </NextModal>
      )}
    </>
  );
});

export default ReviewDashboard;
