import React from 'react';
import { Card, Text, Image, Button } from '@nextui-org/react';
import { PRODUCT_CARD } from '../constants/constant';

interface ProductCardProps {
  imgURL: string;
  name: string;
  mrp: number;
  pdpURL: string;
  brand: string;
  serialNumber?: number;
  highlighted?: boolean;
  onProductCardExtClick?: () => void;
}

const ProductCard: React.FC<ProductCardProps> = ({
  imgURL,
  name,
  mrp,
  pdpURL,
  brand,
  serialNumber,
  highlighted = false,
  onProductCardExtClick
}) => {
  return (
    <Card
      isPressable={!!onProductCardExtClick}
      onClick={(e) => {
        if (!e.defaultPrevented && onProductCardExtClick) {
          onProductCardExtClick();
        }
      }}
      css={{
        width: '100%',
        height: '100%',
        border: highlighted ? '2px solid #0072F5' : '1px solid #eaeaea',
        padding: '8px',
        backgroundColor: 'white',
        boxShadow: highlighted ? '0 4px 14px 0 rgba(0, 114, 245, 0.2)' : 'none',
        transition: 'all 0.2s ease',
        '&:hover': {
          transform: 'translateY(-2px)',
          boxShadow: '0 4px 14px 0 rgba(0, 0, 0, 0.1)',
        }
      }}
    >
      <Card.Body css={{ p: 0, display: 'flex', flexDirection: 'column', height: '100%' }}>
        <div style={{ position: 'relative', height: '240px', marginBottom: '8px' }}>
          {serialNumber && (
            <div style={{
              position: 'absolute',
              top: 0,
              left: 0,
              backgroundColor: 'rgba(0, 0, 0, 0.7)',
              color: 'white',
              padding: '4px 6px',
              borderRadius: '4px',
              fontSize: '11px',
              zIndex: 1
            }}>
              #{serialNumber}
            </div>
          )}
          <Image
            src={imgURL}
            alt={name}
            objectFit="contain"
            width="100%"
            height="100%"
          />
        </div>
        <div style={{ display: 'flex', flexDirection: 'column', flex: 1, minHeight: 0 }}>
          <Text b size={13} css={{ 
            lineHeight: '1.3', 
            display: '-webkit-box', 
            '-webkit-line-clamp': '2', 
            '-webkit-box-orient': 'vertical', 
            overflow: 'hidden',
            marginBottom: 'auto',
            flex: '1 1 auto'
          }}>
            {name}
          </Text>
          <div style={{ 
            display: 'flex', 
            justifyContent: 'space-between', 
            alignItems: 'center', 
            marginTop: '8px',
            marginBottom: '8px'
          }}>
            <Text size={12} css={{ color: '$gray700' }}>
              {brand}
            </Text>
            <Text b size={13}>
              ₹{mrp.toLocaleString()}
            </Text>
          </div>
          <Button
            auto
            css={{
              background: '#0072F5',
              color: 'white',
              width: '100%',
              fontSize: '13px',
              height: '32px',
              minWidth: 'auto',
              padding: '0 12px',
              borderRadius: '6px',
              '&:hover': {
                opacity: 0.9,
                transform: 'translateY(-1px)',
                transition: 'all 0.2s ease',
              }
            }}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              if (pdpURL) {
                window.open(pdpURL, '_blank', 'noopener,noreferrer');
              }
            }}
          >
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '4px' }}>
              View Details
              <svg width="12" height="12" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M21 3V9H19V6.414L12.707 12.707L11.293 11.293L17.586 5H15V3H21ZM19 19H5V5H12V3H5C3.897 3 3 3.897 3 5V19C3 20.103 3.897 21 5 21H19C20.103 21 21 20.103 21 19V12H19V19Z" fill="currentColor"/>
              </svg>
            </div>
          </Button>
        </div>
      </Card.Body>
    </Card>
  );
};

export default ProductCard;
