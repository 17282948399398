import React, { createContext, useContext, useState, useEffect } from "react";
import { getCategories } from "../api/dashboard";

interface CategoryContextType {
  selectedCategory: string | null;
  setSelectedCategory: (category: string) => void;
  categories: Category[];
}

interface Category {
  category_id: string;
  category_name: string;
}

const CategoryContext = createContext<CategoryContextType | undefined>(
  undefined
);

export const CategoryProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [selectedCategory, setSelectedCategory] = useState<string | null>(null);
  const [categories, setCategories] = useState<Category[]>([]);
  const [categoryError, setCategoryError] = useState<string | null>(null);
  const [categoriesLoading, setCategoriesLoading] = useState(false);

  const setSelectedCategoryWithStorage = (category: string) => {
    setSelectedCategory(category);
    localStorage.setItem("lastSelectedCategory", category);
  };

  useEffect(() => {
    const fetchCategories = async () => {
      setCategoriesLoading(true);
      try {
        setCategoryError(null);
        const response = await getCategories();
        if (response?.data) {
          setCategories(response.data);
          // Restore last selected category from localStorage
          const lastSelected = localStorage.getItem("lastSelectedCategory");
          if (
            lastSelected &&
            response.data.some(
              (cat: Category) => cat.category_id === lastSelected
            )
          ) {
            setSelectedCategory(lastSelected);
          }

          const lastSelectedCategory = localStorage.getItem(
            "lastSelectedCategory"
          );
          if (lastSelectedCategory) {
            setSelectedCategory(lastSelectedCategory);
          }
        }
      } catch (error) {
        console.error("Error fetching categories:", error);
        setCategoryError("Failed to load categories");
        setCategories([]);
      } finally {
        setCategoriesLoading(false);
      }
    };

    fetchCategories();
  }, []);

  return (
    <CategoryContext.Provider
      value={{ selectedCategory, setSelectedCategory, categories }}
    >
      {children}
    </CategoryContext.Provider>
  );
};

export const useCategory = () => {
  const context = useContext(CategoryContext);
  if (context === undefined) {
    throw new Error("useCategory must be used within a CategoryProvider");
  }
  return context;
};
